import { $themeConfig } from '@themeConfig';

export default {
  namespaced: true,
  state: {
    layout: {
      isRTL: $themeConfig.layout.isRTL,
      skin: localStorage.getItem('vuexy-skin') || $themeConfig.layout.skin,
      routerTransition: $themeConfig.layout.routerTransition,
      type: $themeConfig.layout.type,
      contentWidth: $themeConfig.layout.contentWidth,
      menu: {
        hidden: $themeConfig.layout.menu.hidden,
      },
      navbar: {
        type: $themeConfig.layout.navbar.type,
        backgroundColor: $themeConfig.layout.navbar.backgroundColor,
      },
      footer: {
        type: $themeConfig.layout.footer.type,
      },
    },
    // proActual:{},
    projecto: {
      id: '',
      name: '',
      currency: '',
      terms: ''
    },
    proyectos: [],
    proyectosTotales: [],
    usuarios: [],
    transacciones: [],
    suscripciones: [],
    linksDePago: [],
    plantillasLinksPago: [],
    usuarioLogeado: {},
    currency: [],
    rol: 0,
    pay: false,
    linkEditar: -1,
    loading: false
  },
  getters: {},
  mutations: {
    changeProyectosStore(state, value) {
      var appl = [];
      for (let i = 0; i < value.length; i++) {
        let existe = false;
        for (let j = 0; j < appl.length; j++) {
          if(value[i].name == appl[j].name ){
            existe = true; break;
          }
        }
        if(!existe){
          appl.push(value[i]);
        }
      }
      
      state.proyectos = appl;
    },
    changeStore(state, pro) {
      state.projecto.id = pro.id;
      state.projecto.name = pro.name;
      state.projecto.currency = pro.currency
      state.projecto.terms = pro.terms

    },
    saveUsuarios(state, value) {
      state.usuarios = value;
    },
    saveTransacciones(state, value) {
      state.transacciones = value;
    },
    saveCurrency(state, value) {
      state.currency = value;
    },
    saveProjectCurrency(state, value) {
      state.projecto.currency = value;
    },
    saveSuscripciones(state, value) {
      state.suscripciones = value;
    },
    saveLinksDePago(state, value) {
      state.linksDePago = value;
    },
    savePlantillasLinksDePago(state, value) {
      state.plantillasLinksPago = value;
    },
    saveProyectosTotales(state, value) {
      state.proyectosTotales = value;
    },
    saveUsuarioLogeado(state, value) {
      state.usuarioLogeado = value;

      switch (value.role) {
        case 'ADMIN':
          state.rol = 3;
          break;
        case 'BASIC':
          state.rol = 2;
          break;
        case 'READ':
          state.rol = 1;
          break;
        default:
          break;
      }
    },
    savePayment(state, value) {
      state.pay = value;
    },

    linkCarga(state, value) {
      state.linkEditar = value;
    },
    changeLoading(state, val) {
      state.loading = val;
    },
    TOGGLE_RTL(state) {
      state.layout.isRTL = !state.layout.isRTL;
      document.documentElement.setAttribute(
        'dir',
        state.layout.isRTL ? 'rtl' : 'ltr'
      );
    },
    UPDATE_SKIN(state, skin) {
      state.layout.skin = skin;

      // Update value in localStorage
      localStorage.setItem('vuexy-skin', skin);

      // Update DOM for dark-layout
      if (skin === 'dark') document.body.classList.add('dark-layout');
      else if (document.body.className.match('dark-layout'))
        document.body.classList.remove('dark-layout');
    },
    UPDATE_ROUTER_TRANSITION(state, val) {
      state.layout.routerTransition = val;
    },
    UPDATE_LAYOUT_TYPE(state, val) {
      state.layout.type = val;
    },
    UPDATE_CONTENT_WIDTH(state, val) {
      state.layout.contentWidth = val;
    },
    UPDATE_NAV_MENU_HIDDEN(state, val) {
      state.layout.menu.hidden = val;
    },
    UPDATE_NAVBAR_CONFIG(state, obj) {
      Object.assign(state.layout.navbar, obj);
    },
    UPDATE_FOOTER_CONFIG(state, obj) {
      Object.assign(state.layout.footer, obj);
    },
  },
  actions: {},
};
