import Vue from 'vue';
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue';
import VueCompositionAPI from '@vue/composition-api';

import i18n from '@/libs/i18n';
import router from './router';
import store from './store';
import App from './App.vue';

// Global Components
import './global-components';

// 3rd party plugins
import '@axios';
import '@/libs/acl';
import '@/libs/portal-vue';
import '@/libs/clipboard';
import '@/libs/toastification';
import '@/libs/sweet-alerts';
import '@/libs/vue-select';
import '@/libs/tour';

// Axios Mock Adapter
import '@/@fake-db/db';

//stripe
import { StripePlugin } from '@vue-stripe/vue-stripe';

const options = {
  pk:
    'pk_test_51JO1lMEIHjJlosBdz4FmJyMrjfoBqrZpkHfszgumyguUDdwbvsDPWM8dzFLBJHjzNjRWDhn4IIErfZ81zcST7DUL00hHM2W7gS',
  stripeAccount: 'acct_1JO1lMEIHjJlosBd',
  apiVersion: '2020-08-27',
  locale: 'es',
};

Vue.use(StripePlugin, options);

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css'); // For form-wizard

// import core styles
require('@core/scss/core.scss');

// import assets styles
require('@/assets/scss/style.scss');

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
