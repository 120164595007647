export default [
  {
    path: '/dashboard/analytics',
    name: 'dashboard-analytics',
    component: () => import('@/views/dashboard/analytics/Analytics.vue'),
  },
  {
    path: '/dashboard/ecommerce',
    name: 'dashboard-ecommerce',
    component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
  },
  //Usuario
  {
    path: '/dashboard/usuarios',
    name: 'usuarios',
    component: () => import('@/views/dashboard/usuarios/Usuarios.vue'),
  },
  {
    path: '/dashboard/usuarios/UsuarioNuevo',
    name: 'usuario-nuevo',
    component: () => import('@/views/dashboard/usuarios/UsuarioNuevo.vue'),
  },
  //Perfil
  {
    path: '/dashboard/perfil',
    name: 'perfil-usuario',
    component: () => import('@/views/dashboard/perfil/PerfilUsuario.vue'),
  },
  //Transacciones
  {
    path: '/dashboard/transaccion',
    name: 'transacciones',
    component: () => import('@/views/apps/user/users-list/UsersList.vue'),
  },
  {
    path: '/dashboard/transaccion/:idSuscripcion',
    name: 'transacciones-suscripcion',
    props: true,
    component: () => import('@/views/apps/user/users-list/UsersList.vue'),
  },
  //Productos
  {
    path: '/dashboard/productos',
    name: 'productos',
    component: () => import('@/views/dashboard/productos/Productos.vue'),
  },
  {
    path: '/dashboard/productos/ProductoNuevo',
    name: 'producto-nuevo',
    component: () => import('@/views/dashboard/productos/ProductoNuevo.vue'),
  },
  {
    path: '/dashboard/productos/ProductoEditar/:productSelected',
    name: 'producto-editar',
    props: true,
    component: () => import('@/views/dashboard/productos/ProductoEditar.vue'),
  },
  //Suscripción
  {
    path: '/dashboard/suscripciones',
    name: 'suscripcion',
    component: () =>
      import('@/views/apps/user/users-list/suscripcion/Suscripciones.vue'),
  },
  {
    path: '/dashboard/suscripciones/SuscripcionEditar/:selectedSuscription',
    name: 'suscripcion-editar',
    props: true,
    component: () =>
      import('@/views/apps/user/users-list/suscripcion/SuscripcionEditar.vue'),
  },
  //Links de Pago
  {
    path: '/dashboard/linksPago',
    name: 'linksPago',
    props: true,
    component: () =>
      import('@/views/apps/user/users-list/linksPago/LinkPago.vue'),
  },

  {
    path: '/dashboard/linksPago/LinkPagoCrear',
    name: 'link-pago-crear',
    component: () =>
      import('@/views/apps/user/users-list/linksPago/LinkPagoCrear.vue'),
  },
  {
    path: '/dashboard/linksPago/LinkPagoEditar',
    name: 'link-pago-editar',
    component: () =>
      import('@/views/apps/user/users-list/linksPago/LinkPagoEditar.vue'),
  },


  //Webhooks
  {
    path: '/dashboard/webhooks',
    name: 'webhooks',
    component: () => import('@/views/dashboard/webhooks/Webhooks.vue'),
  },

  //Soporte
  {
    path: '/dashboard/soporte',
    name: 'soporte',
    component: () => import('@/views/dashboard/Soporte.vue'),
  },
];
