//conexion
//export const backendUrl = 'https://checkout-back.multinetlabs.com/api';
//export const frontEndUrl = 'http://checkout-dev.multinetlabs.com/';
export const frontEndUrl = 'https://checkout.celcomlatam.com/';
export const backendUrl = 'https://apicheckout.celcomlatam.com/api';

// //kushki
// export const celcomKushkiMerchantId = '7d0ec62a12ac4f678bb5fe4f6a5c20cd';
// export const isProduction = false;
// export const ssl = true;
// export const enableLogs = false;
export const urlKushki = 'https://api-uat.kushkipagos.com'
export const TOKEN_TRANSFER_KUSHKI = 'https://api-uat.kushkipagos.com/transfer/v1/tokens';
export const TOKEN_CASH_KUSHKI = 'https://api-uat.kushkipagos.com/cash/v1/tokens';
export const TOKEN_DEBIT_KUSHKI = 'https://api-uat.kushkipagos.com/card-async/v1/tokens';
export const urlEmpello = 'https://antifraud.empello.net/ijlnmqvm/xnionbuz-dev.js';
