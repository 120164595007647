<template>
  <div id="app" class="h-100" :class="[skinClasses]">
    <div id="loadingScreen" v-if="$store.state.appConfig.loading">
      <div id="loading-bg" v-if="$store.state.appConfig.loading">
        <div class="loading">
          <div class="effect-1 effects"></div>
          <div class="effect-2 effects"></div>
          <div class="effect-3 effects"></div>
        </div>
      </div>
    </div>
    <component :is="layout">
      <router-view />
    </component>

    <scroll-to-top v-if="enableScrollToTop" />
  </div>
</template>

<script>
import ScrollToTop from "@core/components/scroll-to-top/ScrollToTop.vue";

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from "@themeConfig";
import { provideToast } from "vue-toastification/composition";
import { watch } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";

import { useWindowSize, useCssVar } from "@vueuse/core";

import store from "@/store";
import { getUserData } from "./auth/utils";
import axios from "axios";
import { backendUrl } from "./config";

const LayoutVertical = () => import("@/layouts/vertical/LayoutVertical.vue");
const LayoutHorizontal = () =>
  import("@/layouts/horizontal/LayoutHorizontal.vue");
const LayoutFull = () => import("@/layouts/full/LayoutFull.vue");

export default {
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

    ScrollToTop,
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === "full") return "layout-full";
      return `layout-${this.contentLayoutType}`;
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type;
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = [
      "primary",
      "secondary",
      "success",
      "info",
      "warning",
      "danger",
      "light",
      "dark",
    ];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement
      ).value.trim();
    }

    // Set Theme Breakpoints
    const breakpoints = ["xs", "sm", "md", "lg", "xl"];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement
        ).value.slice(0, -2)
      );
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout;
    document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr");
  },
  setup() {
    const { skin, skinClasses } = useAppConfig();
    const { enableScrollToTop } = $themeConfig.layout;

    // If skin is dark when initialized => Add class to body
    if (skin.value === "dark") document.body.classList.add("dark-layout");

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: "Vue-Toastification__fade",
    });

    // Set Window Width in store
    store.commit("app/UPDATE_WINDOW_WIDTH", window.innerWidth);
    const { width: windowWidth } = useWindowSize();
    watch(windowWidth, (val) => {
      store.commit("app/UPDATE_WINDOW_WIDTH", val);
    });

    return {
      skinClasses,
      enableScrollToTop,
    };
  },
  created() {
    this.getProject();
  },
  methods: {
    async getProject() {
      if (localStorage.getItem("token")) {
        const userLocalStorage = getUserData();

        const token = localStorage.getItem("token");

        const users = await axios({
          method: "get",
          url: `${backendUrl}/usuarios/`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const user = users.data.user;

        const usuario = await user.filter(
          (e) => e.id === userLocalStorage.idUser
        );

        const productosdb = await axios({
          method: "get",
          url: `${backendUrl}/productos/`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const productos = productosdb.data.project;

        const producto = await productos.filter(
          (e) => e.id == usuario[0].project[0].id_project
        );

        let productosStore = [];

        for (let f = 0; f < usuario[0].project.length; f++) {
          const resProj = await productos.filter(
            (e) => e.id === usuario[0].project[f].id_project
          );
          for (let k = 0; k < resProj.length; k++) {
            productosStore.push({
              id: resProj[k].id,
              name: resProj[k].name,
              currency: resProj[k].id_currency,
              terms: resProj[k].Terms_and_Conditions,
            });
          }
      
        }

        this.$store.commit("appConfig/changeProyectosStore", productosStore);
        
        let auxChangeStore = {
          id : producto[0].id,
          name :producto[0].name,
          currency : producto[0].id_currency,
          terms : producto[0].Terms_and_Conditions
        }
        this.$store.commit("appConfig/changeStore", auxChangeStore /* {
          id,
          name,
          id_currency,
          Terms_and_Conditions,
        } */);
       

        //trae las monedas
        const responseM = await axios({
          method: "get",
          url: `${backendUrl}/operaciones/util`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        let monedas = responseM.data.currencies;
        this.$store.commit("appConfig/saveCurrency", monedas);
        

        //GUARDAR USUARIOS
        this.$store.commit("appConfig/saveUsuarios", users.data.user);

        //GUARDAR USUARIO LOGEADO
        this.$store.commit("appConfig/saveUsuarioLogeado", usuario[0]);

        //GUARDAR PROYECTOS TOTALES

        this.$store.commit(
          "appConfig/saveProyectosTotales",
          productosdb.data.project
        );

        //TRAER TRANSACCIONES
        const responseT = await axios({
          method: "get",
          url: `${backendUrl}/operaciones/transacciones`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        this.$store.commit(
          "appConfig/saveTransacciones",
          responseT.data.transactions
        );

        
        
        //TRAER LINKS DE PAGO
        var config = {
          method: "get",
          url: `${backendUrl}/link-pago/`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const resp = await axios(config);
        this.$store.commit("appConfig/saveLinksDePago", resp.data.link);

        //

        //TRAER SUSCRIPCIONES
        const responseS = await axios({
          method: "get",
          url: `${backendUrl}/operaciones/suscripcion`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        this.$store.commit(
          "appConfig/saveSuscripciones",
          responseS.data.suscriptions
        );

        /* let auxCurrency = monedas.find(element => element.id == this.$store.state.appConfig.projecto.currency);

        this.$store.commit('appConfig/saveProjectCurrency',auxCurrency ); */
      }
    },
  },
};
</script>

<style>
.dropdown-item:hover {
  background-color: #e21a6b !important;
  color: white !important;
}

.dropdown-item:active {
  background-color: #e21a6b !important;
}

#selectProductos .vs__selected-options .vs__selected {
  background: #00cfe8 !important;
  border-radius: 20px;
}

#loadingScreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  background-color: #00000080;
}

/* SPINNER */
#loading-logo {
  width: 50%;
}
#loading-bg {
  width: 100%;
  height: 100%;
  background: transparent;
  display: block;
  position: absolute;
}
.loading-logo {
  position: absolute;
  left: calc(46% - 45px);
  top: 40%;
}
.loading {
  position: absolute;
  left: calc(50% - 35px);
  top: 50%;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 3px solid transparent;
}
.loading .effect-1,
.loading .effect-2 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid rgba(0, 26, 87, 1);
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading .effect-1 {
  animation: rotate 1s ease infinite;
}
.loading .effect-2 {
  animation: rotateOpacity 1s ease infinite 0.1s;
}
.loading .effect-3 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid rgba(0, 26, 87, 1);
  -webkit-animation: rotateOpacity 1s ease infinite 0.2s;
  animation: rotateOpacity 1s ease infinite 0.2s;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading .effects {
  transition: all 0.3s ease;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes rotateOpacity {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 0.1;
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
    opacity: 1;
  }
}
</style>
